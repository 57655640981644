import request from '@/utils/request';

const PATH = '/business/apply';

// export const getBusinessApplyList = (params: any) => request({
//   url: PATH,
//   method: 'get',
//   params,
// });

export const getBusinessApplyList = (params: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...params,
    page: params.page - 1,
  },
});

export const getBusinessApplyFileList = (idx: any) => request({
  url: `${PATH}/${idx}/files`,
  method: 'get',
});

export const addBusinessApply = (businessIdx: any, data: any) => request({
  url: `${PATH}/${businessIdx}`,
  method: 'post',
  data,
});

export const updateBusinessApplyFile = (idx: any, data: any) => request({
  url: `${PATH}/${idx}/file`,
  method: 'put',
  data,
});

export const updateBusinessApplyStatus = (idx: any, data: any) => request({
  url: `${PATH}/${idx}`,
  method: 'put',
  data,
});
