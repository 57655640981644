
import { uploadFile } from '@/api/attachedFile';
import { getBusinessApplyFileList, updateBusinessApplyFile } from '@/api/businessApply';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'MypageApplyFile',
  components: {
  },
})
export default class extends Vue {
  @Prop({ required: true }) private idx!: any;

  @Prop({ required: true }) private applyStatus!: any;

  mounted() {
    this.getApplyFileList();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private fileList = [];

  private getApplyFileList() {
    getBusinessApplyFileList(this.idx).then((res) => {
      this.fileList = res.data;
    });
  }

  private handleChangeFile(e: any, fileIdx: any) {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 10) {
      alert('최대 10MB 까지 업로드 가능합니다.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    uploadFile('client/business/apply', formData).then((res) => {
      const data = {
        fileUid: res.data.uid,
        businessFileIdx: fileIdx,
      };
      updateBusinessApplyFile(this.idx, data).then((res2) => {
        alert('파일이 수정되었습니다.');
        this.getApplyFileList();
      });
    });
  }
}
