
import { Vue, Component, Watch } from 'vue-property-decorator';
import Join from '@/views/user/join.vue';
import { UserModule } from '@/store/user';
import Startup from './components/Startup.vue';
import Bookmark from './components/Bookmark.vue';
import Apply from './components/Apply.vue';

@Component({
  name: 'MyPage',
  components: {
    Join,
    Bookmark,
    Apply,
    Startup,
  },
})
export default class extends Vue {
  get routerName() {
    return this.$route.name;
  }

  @Watch('tabIndex')
  private handleChangeTabIndex(index: number) {
    if (this.$route.query.tab !== String(index)) {
      this.$router.push({ name: this.routerName || '', query: { tab: String(index) } });
    }
  }

  mounted() {
    if (!UserModule.token) {
      if (this.routerName !== 'MyPage') this.$router.push('/app/login');
      else this.$router.push('/');
    }
    if (this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab);
  }

  private tabIndex = 2;

  private labels = ['즐겨찾기(게시글)', '즐겨찾기(창업기업)', '지원사업조회', '개인정보수정'];

  private handleClickTab(tabIndex: number) {
    this.tabIndex = tabIndex;
  }
}
