
import { getBusinessNoticeList } from '@/api/businessNotice';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'MypageApplyNoticeDetail',
  components: {
  },
})
export default class extends Vue {
  @Prop({ required: true }) private businessIdx!: any;

  mounted() {
    this.getBusinessNoticeList();
  }

  private noticeList = [];

  private activeNoticeIdx: any = null;

  private getBusinessNoticeList() {
    getBusinessNoticeList(this.businessIdx).then((res) => {
      this.noticeList = res.data;
    });
  }

  private handleClickNotice(idx: any) {
    if (this.activeNoticeIdx === idx) this.activeNoticeIdx = null;
    else this.activeNoticeIdx = idx;
  }
}
