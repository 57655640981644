
import { Vue, Component, Prop } from 'vue-property-decorator';
import BusinessNotice from './BusinessNotice.vue';
import ApplyFile from './ApplyFile.vue';

@Component({
  name: 'MypageApplyDetail',
  components: {
    BusinessNotice,
    ApplyFile,
  },
})
export default class extends Vue {
  @Prop({ default: '' }) private idx!: any;

  @Prop({ default: '' }) private businessName!: any;

  @Prop({ default: '' }) private businessIdx!: any;

  @Prop({ default: 0 }) private applyStatus!: any;

  private applyTabContent = 0;

  private fileList = [];

  private handleClose() {
    this.$emit('close');
  }
}
