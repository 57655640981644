
import { Vue, Component, Prop } from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';
import { addInterestCompany, deleteInterestCompany, getBookmarkList } from '@/api/interestCompany';

@Component({
  name: 'Startup',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  mounted() {
    this.getBookmarkList();
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getBookmarkList();
  }

  private getBookmarkList() {
    getBookmarkList(this.listQuery).then((res) => {
      this.bookmarkList = res.data.content;
      this.listTotal = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
  }

  private bookmarkList = [];

  private listTotal = 0;

  private totalPages = 1;

  private listQuery = {
    page: 0,
    size: 16,
    searchValue: '',
  };

  private handleClickInterest(uid: string) {
    if (window.confirm('정말 즐겨찾기에서 제거하시겠습니까?')) {
      deleteInterestCompany(uid).then(() => {
        this.getBookmarkList();
      });
    }
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getBookmarkList();
  }
}
