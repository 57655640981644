
import { deleteBookmark, getBookmarkList } from '@/api/userBookmark';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Pagination from '@/components/board/pagination.vue';
import { getBookmarkBoardList } from '@/api/board';
import moment from 'moment';
import { IPost } from '@/types';

@Component({
  name: 'Bookmark',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getBookmarkBoardList();
  }

  private listQuery = {
    page: 0,
    size: 10,
    boardUid: '',
    searchType: 'title',
    searchValue: '',
  };

  private bookmarks: any = [];

  private totalElements = 0;

  private totalPages = 0;

  private boards: any = [];

  private searchedBoardUid = '';

  private handleSearch() {
    getBookmarkList(this.listQuery).then((res) => {
      if (this.listQuery.page > 0 && res.data.content.length === 0) {
        this.listQuery.page -= 1;
        this.handleSearch();
      } else {
        this.bookmarks = res.data.content;
        this.totalElements = res.data.totalElements;
        this.totalPages = res.data.totalPages;
        this.searchedBoardUid = this.listQuery.boardUid;
      }
    });
  }

  private getBookmarkBoardList() {
    getBookmarkBoardList().then((res) => {
      this.boards = res.data;
      if (res.data.length > 0) {
        this.listQuery.boardUid = res.data[0].uid;
        this.handleSearch();
      }
    });
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.handleSearch();
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3].inputValue || !post.dataList[4].inputValue) return '진행중';
    const today = moment(moment().format('YYYY-MM-DD 00:00:00'));
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 1) return `마감 ${remain} 일전`;
    if (remain >= 0) return '오늘 마감';
    return '마감';
  }

  private isEndClass(post: IPost) {
    if (!post.dataList[4].inputValue) return 'state01';
    const today = moment();
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 30) return 'state03';
    if (remain >= 7) return 'state02';
    if (remain >= 0) return 'state01';
    return 'state00';
  }

  private handleDeleteBookmark(postUid: string) {
    if (window.confirm('정말 즐겨찾기에서 제외하시겠습니까?')) {
      deleteBookmark(postUid).then(() => {
        this.handleSearch();
      });
    }
  }

  private getMenuUid() {
    const boardIndex = this.boards.findIndex((board: any) => board.uid === this.searchedBoardUid);
    if (boardIndex > -1) {
      const board = this.boards[boardIndex];
      if (board.menuList.length > 0) return board.menuList[0];
    }
    return '';
  }

  private getAppRouter(post: any) {
    if (post.boardUid === 'dc1e2055-0600-42b6-800b-904b18dfcdab') return 'AppSupportDetail';
    return 'AppEventDetail';
  }
}
